<template>
  <div>
    <b-form-input
      v-if="allows_crud"
      class="input-number"
      :class="{
        'score-input-warning': maxWeighing < 0,
      }"
      v-b-tooltip.top.noninteractive.v-danger="
        `${
          maxWeighing < 0
            ? 'Cambio no guardado dado que la ponderación supera el 100%.'
            : ''
        }`
      "
      type="number"
      min="0"
      :max="maxWeighing + parseFloat(test_evaluation_criteria_micro.weighing)"
      size="sm"
      v-model="test_evaluation_criteria_micro.weighing"
      @input="changeWeighing"
    >
    </b-form-input>
    <div v-else>{{ test_evaluation_criteria_micro.weighing }}%</div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
export default {
  name: "DuocSpecificationTableInput",
  components: {},
  props: {
    test_id: {
      type: Number,
      required: true,
    },
    evaluation_criteria_micro_id: {
      type: Number,
      required: true,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      test_evaluation_criteria_micro: {
        id: generateUniqueId(),
        weighing: 0,
        test: this.test_id,
        evaluation_criteria_micro: this.evaluation_criteria_micro_id,
      },
    };
  },
  computed: {
    ...mapGetters({
      test_evaluation_criteria_micros:
        names.NEW_TEST_EVALUATION_CRITERIA_MICROS,
    }),
    testEvaluationCriteriaMicros() {
      return this.test_evaluation_criteria_micros.filter(
        (x) =>
          x.test == this.test_id &&
          this.evaluation_criteria_micro_ids.includes(
            x.evaluation_criteria_micro
          )
      );
    },
    testEvaluationCriteriaMicro() {
      return this.testEvaluationCriteriaMicros.find(
        (x) => x.evaluation_criteria_micro == this.evaluation_criteria_micro_id
      );
    },
    maxWeighing() {
      return (
        100 -
        this.testEvaluationCriteriaMicros.reduce((acc, v_actual) => {
          let v_convert =
            v_actual.weighing === null ||
            v_actual.weighing === "" ||
            v_actual.id === this.test_evaluation_criteria_micro.id
              ? 0
              : v_actual.weighing;
          return acc + (v_convert > 0 ? parseFloat(v_convert) : 0);
        }, 0) -
        parseFloat(this.test_evaluation_criteria_micro.weighing)
      );
    },
  },
  methods: {
    changeWeighing(value) {
      this.debounceTestEvaluationCriteria({ weighing: value });
    },
    debounceTestEvaluationCriteria(item) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (
          item.weighing >= 0 &&
          item.weighing != null &&
          item.weighing != "" &&
          this.maxWeighing >= 0
        ) {
          if (isNaN(this.test_evaluation_criteria_micro.id))
            this.createdTestEvaluationCriteria();
          else this.patchTestEvaluationCriteria(item);
        }
      }, 750);
    },
    createdTestEvaluationCriteria() {
      this.$store
        .dispatch(
          names.POST_NEW_TEST_EVALUATION_CRITERIA_MICRO,
          this.test_evaluation_criteria_micro
        )
        .then((response) => {
          this.test_evaluation_criteria_micro = {
            ...response,
          };
          toast("Poderación actualizada");
        });
    },
    patchTestEvaluationCriteria(item) {
      this.$store
        .dispatch(names.PATCH_NEW_TEST_EVALUATION_CRITERIA_MICRO, {
          new_test_evaluation_criteria_micro_id:
            this.test_evaluation_criteria_micro.id,
          item: item,
        })
        .then((response) => {
          this.test_evaluation_criteria_micro = {
            ...response,
          };
          toast("Poderación actualizada");
        });
    },
  },
  watch: {
    testEvaluationCriteriaMicro(value) {
      if (value != null)
        this.test_evaluation_criteria_micro = {
          ...this.testEvaluationCriteriaMicro,
        };
    },
  },
  mounted() {
    if (this.testEvaluationCriteriaMicro)
      this.test_evaluation_criteria_micro = {
        ...this.testEvaluationCriteriaMicro,
      };
  },
  created() {},
};
</script>

<style scoped>
.input-number {
  --bottom-border-color: var(--kl-menu-color);
  /* max-width: 52px; */
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
.input-number:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
.score-input-warning {
  border: 1px solid #dc3545 !important;
  color: red;
}
</style>

