var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.allows_crud)?_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
      `${
        _vm.maxWeighing < 0
          ? 'Cambio no guardado dado que la ponderación supera el 100%.'
          : ''
      }`
    ),expression:"\n      `${\n        maxWeighing < 0\n          ? 'Cambio no guardado dado que la ponderación supera el 100%.'\n          : ''\n      }`\n    ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],staticClass:"input-number",class:{
      'score-input-warning': _vm.maxWeighing < 0,
    },attrs:{"type":"number","min":"0","max":_vm.maxWeighing + parseFloat(_vm.test_evaluation_criteria_micro.weighing),"size":"sm"},on:{"input":_vm.changeWeighing},model:{value:(_vm.test_evaluation_criteria_micro.weighing),callback:function ($$v) {_vm.$set(_vm.test_evaluation_criteria_micro, "weighing", $$v)},expression:"test_evaluation_criteria_micro.weighing"}}):_c('div',[_vm._v(_vm._s(_vm.test_evaluation_criteria_micro.weighing)+"%")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }